import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes,Navigate  } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar'
import HomePage from './pages/HomePage'
import Dashboard from './pages/Dashboard'
function App() {
  const loadingBarRef = React.createRef(); // Tạo một biến ref

  return (
     <Router>
      <LoadingBar color="#900C3F" height='4px' ref={loadingBarRef} /> {/* Sử dụng biến ref ở đầu trang */}
      <div>
        <Routes>
          <Route path="/" element={<HomePage loadingBarRef={loadingBarRef}/>} />
          <Route path="/login" element={<Dashboard loadingBarRef={loadingBarRef} currentPage="login"/>} />
          <Route
            path="/general"
            element={<Navigate to="/general/overview" />} 
          />
          <Route path="/general/overview" element={<Dashboard loadingBarRef={loadingBarRef} currentPage="overview" />} />
          <Route path="/general/proxy" element={<Dashboard loadingBarRef={loadingBarRef} currentPage="proxy" />} />

          <Route
            path="/apps"
            element={<Navigate to="/apps/transactions-history" />} 
          />
          <Route path="/apps/transactions-history" element={<Dashboard loadingBarRef={loadingBarRef} currentPage="transactions-history" />} />
          <Route path="/apps/members-management" element={<Dashboard loadingBarRef={loadingBarRef} currentPage="members-management" />} />

          <Route
            path="/statistical"
            element={<Navigate to="/statistical/user-analytics" />} 
          />
          <Route path="/statistical/user-analytics" element={<Dashboard loadingBarRef={loadingBarRef} currentPage="user-analytics" />} />
          <Route path="/statistical/sites-analytics/" element={<Dashboard loadingBarRef={loadingBarRef} currentPage="sites-analytics" />} />
          <Route
            path="/management"
            element={<Navigate to="/management/sites/" />} 
          />
          <Route path="/management/sites/" element={<Dashboard loadingBarRef={loadingBarRef} currentPage="sites-management" />} />
          <Route path="/management/networks/" element={<Dashboard loadingBarRef={loadingBarRef} currentPage="networks-management" />} />
          <Route path="/management/employee/" element={<Dashboard loadingBarRef={loadingBarRef} currentPage="employee" />} />

          <Route
            path="/clicker"
            element={<Navigate to="/clicker/clickersetting/" />} 
          />
          <Route path="/clicker/clickersetting/" element={<Dashboard loadingBarRef={loadingBarRef} currentPage="clickersetting" />} />
          <Route path="/crypto" element={<Dashboard loadingBarRef={loadingBarRef} currentPage="crypto" />} />
        </Routes>
      </div>
     
    </Router>
  );
}

export default App;
