import { configureStore } from '@reduxjs/toolkit';
import statisticsReducer from '../services/statisticsSlice'; // Ensure paths are correct
import chartsReducer from '../services/chartsSlice';
import tableReducer from '../services/tableSlice';
import proxyReducer from '../services/proxySlice';
import appsReducer from '../services/appsSlice';
import analyticsReducer from '../services/analyticsSlice';
import managementReducer from '../services/managermentSlice'; // Newly added
import userReducer from '../services/userSlice';
import dateRangeReducer from '../services/dateRangeSlice';
import clickerReducer from '../services/clickerSlice';
import cryptoReducer from '../services/cryptoSlice'; // Newly added

export const store = configureStore({
  reducer: {
    dateRange: dateRangeReducer,
    statistics: statisticsReducer,
    charts: chartsReducer,
    table: tableReducer,
    proxy: proxyReducer,
    apps: appsReducer,
    analytics: analyticsReducer,
    management: managementReducer,
    user: userReducer,
    clicker: clickerReducer,
    cryptos: cryptoReducer, // Newly added
  },
});
