// services/cryptoSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './axios'; // Ensure the path is correct

// Initial state for the crypto slice
const initialState = {
    cryptosData: [],
    loading: false,
    error: null,
};

// Async thunk to fetch cryptos data
export const fetchCryptosData = createAsyncThunk(
    'cryptos/fetchCryptosData',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get('/api/cryptos/');
            console.log(response)
            return response;
        } catch (error) {
            console.log('deo')
            return rejectWithValue(error.response.data);
        }
    }
);

// Define the crypto slice
const cryptoSlice = createSlice({
    name: 'cryptos',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCryptosData.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCryptosData.fulfilled, (state, action) => {
                state.cryptosData = action.payload;
                state.loading = false;
            })
            .addCase(fetchCryptosData.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    },
});

export default cryptoSlice.reducer;
